import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Icon4(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
  <path d="M46.667 4.66669H9.33366C6.76699 4.66669 4.66699 6.76669 4.66699 9.33335V51.3334L14.0003 42H46.667C49.2337 42 51.3337 39.9 51.3337 37.3334V9.33335C51.3337 6.76669 49.2337 4.66669 46.667 4.66669ZM44.3337 30.3334L38.5003 26.8334L32.667 30.3334V11.6667H44.3337V30.3334Z" fill="url(#paint0_linear_175_176)"/>
  <defs>
    <linearGradient id="paint0_linear_175_176" x1="28.0003" y1="4.66669" x2="28.0003" y2="51.3334" gradientUnits="userSpaceOnUse">
      <stop stop-color="#007F85"/>
      <stop offset="1" stop-color="#FEDF62"/>
    </linearGradient>
  </defs>
</svg>
      </SvgIcon>
	);
  }
  
  export default Icon4;
  