import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Api_url } from './helper';
import axios from 'axios';
import SwipeableViews from 'react-swipeable-views';
import { Typography, Grid } from '@mui/material';

const ImageWithSkeleton = ({ mobile }) => (
  <Grid container lg={3} xs={12} className="image-container" sx={{ marginBottom: '26px' }}>
    <Grid item lg={12} xs={10}>
      <Skeleton variant="rectangular" animation="wave" width={mobile ? '90px' : '144px'} height={mobile ? '150px' : '200px'} />
    </Grid>
   
  </Grid>
);

function ImageSlider1() {
  const mobile = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${Api_url}/Otp/api/images/homes`).then((response) => {
      setImages(response.data);
      setLoading(false);
    });
  }, []);

	const imagesPerPage = mobile ? 3 : 7;
	const imagesPerPage1 = mobile ? 1 : 4;
  const numSets = Math.ceil(images.length / imagesPerPage);
	const handleFileviewer = (selectedImageId) => {
		window.location.href = `/file-viewer?fileId=${selectedImageId}`;
}
  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex((prevIndex) => (prevIndex === numSets - 1 ? 0 : prevIndex + 1));
    }, 2000); // Auto-advance every 5 seconds

    return () => clearInterval(interval);
  }, [numSets]);

  return (
    <Grid container lg={12} xs={12}>
      <Grid container lg={10.3} xs={10.3} sx={{ margin: 'auto', marginBottom: mobile ? "0px" : '100px', marginTop: '26px', justifyContent: mobile ? 'initial' : '' }}>
        <Grid container lg={12} xs={11}>
          {loading ? (
            Array.from({ length: imagesPerPage1 }).map((_, index) => (
              <ImageWithSkeleton key={`skeleton-${index}`} mobile={mobile} />
            ))
          ) : (
            <SwipeableViews index={startIndex} onChangeIndex={(index) => setStartIndex(index)}>
              {Array.from({ length: numSets }).map((_, index) => (
                <div key={`image-set-${index}`}>
                  {images.slice(index * imagesPerPage, (index + 1) * imagesPerPage).map((image) => (
                    <Grid container lg={mobile ? 12 : 1.7} xs={4} key={image._id} className="image-container" sx={{ marginBottom: mobile ? "0px" : '26px' }}>
                      <img src={`data:image/jpeg;base64,${image.imageData}`} alt={image.pdfName} width={mobile ? '90px' : '144px'} height={mobile ? '150px' : '200px'} style={{ borderRadius: '12px' }} onClick={() => handleFileviewer(image._id)} />
                     
                    </Grid>
                  ))}
                </div>
              ))}
            </SwipeableViews>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ImageSlider1;
