import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Icon6(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
  <path d="M42.0003 9.33331L46.667 18.6666H39.667L35.0003 9.33331H30.3337L35.0003 18.6666H28.0003L23.3337 9.33331H18.667L23.3337 18.6666H16.3337L11.667 9.33331H9.33366C8.09598 9.33331 6.909 9.82498 6.03383 10.7001C5.15866 11.5753 4.66699 12.7623 4.66699 14V42C4.66699 43.2377 5.15866 44.4246 6.03383 45.2998C6.909 46.175 8.09598 46.6666 9.33366 46.6666H46.667C47.9047 46.6666 49.0917 46.175 49.9668 45.2998C50.842 44.4246 51.3337 43.2377 51.3337 42V9.33331H42.0003Z" fill="url(#paint0_linear_175_180)"/>
  <defs>
    <linearGradient id="paint0_linear_175_180" x1="28.0003" y1="9.33331" x2="28.0003" y2="46.6666" gradientUnits="userSpaceOnUse">
      <stop stop-color="#007F85"/>
      <stop offset="1" stop-color="#FEDF62"/>
    </linearGradient>
  </defs>
</svg>
      </SvgIcon>
	);
  }
  
  export default Icon6;
  