import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Arrowficon(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
       <svg xmlns="http://www.w3.org/2000/svg" width="325" height="16" viewBox="0 0 325 16" fill="none">
  <path d="M324.707 8.70711C325.098 8.31658 325.098 7.68342 324.707 7.29289L318.343 0.928932C317.953 0.538408 317.319 0.538408 316.929 0.928932C316.538 1.31946 316.538 1.95262 316.929 2.34315L322.586 8L316.929 13.6569C316.538 14.0474 316.538 14.6805 316.929 15.0711C317.319 15.4616 317.953 15.4616 318.343 15.0711L324.707 8.70711ZM0 9L324 9V7L0 7L0 9Z" fill="#B9FD50"/>
</svg>
      </SvgIcon>
	);
  }
  
  export default Arrowficon;
  
