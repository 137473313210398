import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Icon5(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
  <path d="M41.9997 51.3334C43.2374 51.3334 44.4243 50.8417 45.2995 49.9665C46.1747 49.0913 46.6663 47.9044 46.6663 46.6667V9.33335C46.6663 8.09568 46.1747 6.90869 45.2995 6.03352C44.4243 5.15835 43.2374 4.66669 41.9997 4.66669H27.9997V21L22.1663 17.5L16.333 21V4.66669H13.9997C12.762 4.66669 11.575 5.15835 10.6998 6.03352C9.82467 6.90869 9.33301 8.09568 9.33301 9.33335V46.6667C9.33301 47.9044 9.82467 49.0913 10.6998 49.9665C11.575 50.8417 12.762 51.3334 13.9997 51.3334H41.9997Z" fill="url(#paint0_linear_175_178)"/>
  <defs>
    <linearGradient id="paint0_linear_175_178" x1="27.9997" y1="4.66669" x2="27.9997" y2="51.3334" gradientUnits="userSpaceOnUse">
      <stop stop-color="#007F85"/>
      <stop offset="1" stop-color="#FEDF62"/>
    </linearGradient>
  </defs>
</svg>
      </SvgIcon>
	);
  }
  
  export default Icon5;
  