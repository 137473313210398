import React from 'react'
import { styled } from '@mui/system';
import { Button,Grid, Typography} from '@mui/material';
import Image from 'mui-image'
import {useMediaQuery} from '@mui/material';
import Header from './Header';
import Header1 from './Header1';
import { motion } from 'framer-motion';
import Footer1 from './Footer1';
import Brew from './Image/Left.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
// import 'typeface-Outfit';
import "@fontsource/outfit";
import { Link, useNavigate } from 'react-router-dom';
import {SvgIcon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slush from './Image/jumpslush.jpg';
import Slush1 from './Image/1.png';
import Slush2 from './Image/2.png';
import Slush3 from './Image/3.png';
import Slush4 from './Image/4.png';
import Slush5 from './Image/Shakuni.png';
import Slush6 from './Image/5.png'
import Slush7 from './Image/6.png'
import Slush8 from './Image/Kaliyuga.png'
import Slush9 from './Image/7.png'
import Slush10 from './Image/8.png'
import Slush11 from './Image/9.png'
import Star from './Image/Star.png'
import Slush12 from './Image/10.png'
import Slush13 from './Image/11.png'
import { LoginSocialFacebook,LoginSocialGoogle } from 'reactjs-social-login'
import {FacebookLoginButton,GoogleLoginButton} from 'react-social-login-buttons'
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { IconButton } from '@mui/material';
import { Helmet } from 'react-helmet';
import ImageSlider from './Slider';
import './loading.css';
import Mobileheader from './Mobileheader';

import { IcSharpRocketLaunch } from './Rocket'
import {MaterialSymbolsBook2Rounded} from './Icon3'
import {MaterialSymbolsHowToVoteRounded} from './Icon2'
import Arrowficon from './Arrowf'
import Icon4 from './Icon4';
import Icon5 from './Icon5';
import Icon6 from './Icon6';
import Mobileheader1 from './Mobileheader1';
import ImageSlider1 from './ImageSlider1';
const StyledImage = styled(Image)(({ theme }) => ({
  '& .mui-image-wrapper': {
   backgroundColor:'red',

  },
}));
const CustomButton = styled(Button)`
  &:hover {
    background-color: #086266; /* or specify the desired background color */
  }
`;
const CustomButton1 = styled(Button)`
  &:hover {
    background-color: #F4F1F1; /* or specify the desired background color */
  }
`;

function Landingpage2() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorEl2, setArchorEl2]=useState(null)
  const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const handleSignup = () => { 
		navigate('/signup')
	}
  const handleClose = () => {
    setAnchorEl(null);
   
	};
	const Gotopage = () => { 
		navigate('/signup')
	}
	const Gotopage1 = () => { 
		navigate('/login')
	}
	const handleClose2 = () => {
		setArchorEl2(null);
	   
		};
		const Gotopage2 = () => { 
			navigate('/signup', { state: { fromReaderup: true } })
		}
		const Gotopage3 = () => { 
			navigate('/login', { state: { fromReaderup: true } })
		}
  const handleClosepopup =() =>{
    setOpen(false)
    // console.log('yess')
	}
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [isHovering, setIsHovering] = useState(false);
	const gridWidth = mobile?275:1260; // Fixed width of the grid
	const pointerWidth = 200;
	const handleMouseMove = (e) => {
		const { clientX, clientY } = e;
		const maxX = gridWidth - pointerWidth; // Maximum X position within the grid
	
		// Calculate new pointer position, ensuring it stays within grid bounds
		const newX = Math.min(clientX, maxX);
		const newY = clientY; // You can adjust newY based on your specific needs
	
		setPosition({ x: newX, y: newY });
	};
  
	const handleMouseEnter = () => {
	  setIsHovering(true);
	};
  
	const handleMouseLeave = () => {
	  setIsHovering(false);
	};
	const handleClosepopup1 =() =>{
		setOpen1(false)
		// console.log('yess')
		}
	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
	  }, []);
  return (
	  <>
		  <Helmet>
		  <meta name="title" content="Jump the Slush at Slushie - Upload and Publish Your Manuscript with Ease" />
     
         
        <meta name="description" content="Join Slushie, the premier platform for authors, and effortlessly upload, share, and publish your manuscripts. Take the next step in your writing journey and jump the slush with us today." />
      </Helmet>
 {mobile?<Mobileheader1/>:<Header1/>}
     <Grid container lg={12}    >
				<Grid container lg={12} xs={12} sx={{background:'#111204'}} onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
              <Grid container lg={8}  xs={9.5} sx={{margin:mobile?'auto':'auto',justifyContent:'center',marginTop:mobile?'39px':'56px'}}>
							<Grid item  lg={9.8} sx={{margin:mobile?'':'',display:'flex',justifyContent:'center'}} >
							<Typography  sx={{ fontWeight:mobile?'800': '800', fontSize:mobile?'32px': '96px', color:'#FFF',  lineHeight:mobile?'normal': '103px', fontFamily:mobile?'Outfit':'Outfit',fontStyle:'normal'}}>
              Discover 
The{mobile?<br/>:<br/>} Next  <span style={{ backgroundImage: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: 'transparent', WebkitBackgroundClip: 'text', backgroundClip: 'text' }}>Bestseller!</span>
</Typography>
					  </Grid>
					  <Grid item lg={0.2}>
						  <img src={Star} style={{width:mobile?"54px":'112px',height:mobile?"54px":'112px'}} />
					  </Grid>
							
						
								
										<Grid item  lg={8} xs={12} sx={{marginTop:mobile?"16px":'36px'}} >
							  <Typography sx={{ fontWeight: mobile ? '400' : '400', fontSize: mobile ? '14px' : '20px', fontFamily: mobile ? 'Outfit' : 'Outfit', fontStyle: 'normal', lineHeight: '130%',color:'#FFF',textAlign:mobile?'center':"center"
							   }}>
							  Choose a story that you want to read from a pool of manuscripts and make it your Next Bestseller.
	</Typography>
							</Grid>
              {/* <Grid item lg={11}sx={{marginTop:'16px'}} >
									<Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Outfit',fontStyle:'normal',lineHeight: '130%'  }}>We help you <span style={{fontWeight:'600'}}>escape that slush pile</span> as we believe there is no such thing as a bad story.</Typography>
								</Grid> */}
								{/* <Grid item lg={10.5}sx={{marginTop:'16px'}} >
									<Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Outfit',fontStyle:'normal',lineHeight: '130%'  }}>Choose a story that you want to read from a pool of manuscripts and make it your <span style={{fontWeight:'600'}}>Next Bestseller.</span> </Typography>
								</Grid> */}
								
		
						
					  {token ?"":
					  	 <Grid container direction="row"  lg={8.8} xs={10}  sx={{marginTop:'32px',justifyContent:'center'}}>
  <Grid item lg={3.8} xs={6} >
							  <CustomButton onClick={() => setOpen1(true)} variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Outfit' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height: mobile?'40px':"48px" ,  background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: '#111204',fontStyle:'normal' }}>
      Reader
							  </CustomButton>
							  <Dialog open={open} PaperProps={{
										style: {
										  display: "flex",
										  justifyContent: "flex-end",
                      width: "382px", 
      height: "455px",borderRadius:'26px'
										  
										}
									  }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
								>
							
        
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' , fontSize: '26px',fontWeight:'700',fontFamily:'Outfit', color: '#333333',marginTop: '20px' }}>
      {"Join Slushie"}
      <IconButton onClick={handleClosepopup} color="primary" style={{ position: 'absolute', right: 20, top: 20 }}>
        <CloseIcon style={{ color: '#1e1e1e' }} />
      </IconButton>
    </DialogTitle>
    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' ,fontSize: '12px',fontWeight:'500',fontFamily:'Outfit',marginTop: '-25px'  }}>
      Choose your next  bestseller <br /> with just one click
    </DialogTitle>
          <DialogContent style={{overflow:'clip'}}>
          <Grid container lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
											  
		
											  <LoginSocialFacebook
												 
		appId="305111921952755"
		onResolve={(response) => {
			// console.log(response);
			// console.log(response.data);
					  
		
			localStorage.setItem('user', response.data.name);
			localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries')
		
		}}
		onReject={(error) => {
		  console.log(error);
		}}
	  >
		<FacebookLoginButton  text="Sign up with Facebook" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Outfit',fontWeight:500,width:'271px',height:'46px'}} />
										  </LoginSocialFacebook>
										  </Grid>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
										  <LoginSocialGoogle client_id='110556347145-1sehcpdghgh801c1jgb62s02ns8f2a4k.apps.googleusercontent.com'
				  onResolve={(response) => {
					//   console.log(response.data);
					  
		
					  localStorage.setItem('user', response.data.name);
					  localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries')
					
				  }}
				  onReject={(error) => {
					console.log(error);
				  }}>
					  <GoogleLoginButton text="Sign up with Google" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Outfit',fontWeight:500,width:'271px',height:'46px'}}/>
											  </LoginSocialGoogle>
											  </Grid>
    </Grid>
  
    
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
      <Typography variant="body1" style={{ fontSize: '12px',fontFamily:'Outfit', color: '#333333' }}>
        OR
      </Typography>
    </Grid>
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
        <Button
      variant="contained"
      color="primary"
											  onClick={Gotopage}
      style={{ borderRadius: '36px', backgroundColor: '#000000',textTransform: 'none',fontSize:'16px',fontFamily:'Outfit',fontWeight:600,width:'271px',height:'46px' }}
    >
      Sign up with Email
    </Button>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'12px'}}>
       
		<Typography onClick={Gotopage1 } sx={{fontSize: '12px',fontFamily:'Outfit', color: '#333333',fontWeight:'500',color:'#5B5A5A'}}> Already have an account? <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Login</span></Typography>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'26px'}}>
          <Typography style={{ fontSize: '12px',fontFamily:'Outfit', color: '#333333',fontWeight:'500' }}>By continuing, you agree to our <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Terms of <br/> Service</span>   and <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Privacy Policy.</span></Typography>
        </Grid>
      </DialogContent>
          <DialogActions style={{ justifyContent: "space-between"}}>
										
          
			
						
          </DialogActions>
								  </Dialog>
								  <Dialog open={open1} PaperProps={{
										style: {
										  display: "flex",
										  justifyContent: "flex-end",
                      width: "382px", 
      height: "455px",borderRadius:'26px'
										  
										}
									  }}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
								>
							
        
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' , fontSize: '26px',fontWeight:'700',fontFamily:'Outfit', color: '#333333',marginTop: '20px' }}>
      {"Join Slushie"}
      <IconButton onClick={handleClosepopup1} color="primary" style={{ position: 'absolute', right: 20, top: 20 }}>
        <CloseIcon style={{ color: '#1e1e1e' }} />
      </IconButton>
    </DialogTitle>
    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' ,fontSize: '12px',fontWeight:'500',fontFamily:'Outfit',marginTop: '-25px'  }}>
      Choose your next  bestseller <br /> with just one click
    </DialogTitle>
          <DialogContent style={{overflow:'clip'}}>
          <Grid container lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
											  
		
											  <LoginSocialFacebook
												 
		appId="305111921952755"
		onResolve={(response) => {
			// console.log(response);
			// console.log(response.data);
					  
		
			localStorage.setItem('user', response.data.name);
			localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries', { state: { fromReaderup: true } })
		
		}}
		onReject={(error) => {
		  console.log(error);
		}}
	  >
		<FacebookLoginButton  text="Sign up with Facebook" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Outfit',fontWeight:500,width:'271px',height:'46px'}} />
										  </LoginSocialFacebook>
										  </Grid>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
										  <LoginSocialGoogle client_id='110556347145-1sehcpdghgh801c1jgb62s02ns8f2a4k.apps.googleusercontent.com'
				  onResolve={(response) => {
					//   console.log(response.data);
					  
		
					  localStorage.setItem('user', response.data.name);
					  localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries', { state: { fromReaderup: true } })
					
				  }}
				  onReject={(error) => {
					console.log(error);
				  }}>
					  <GoogleLoginButton text="Sign up with Google" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Outfit',fontWeight:500,width:'271px',height:'46px'}}/>
											  </LoginSocialGoogle>
											  </Grid>
    </Grid>
  
    
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
      <Typography variant="body1" style={{ fontSize: '12px',fontFamily:'Outfit', color: '#333333' }}>
        OR
      </Typography>
    </Grid>
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
        <Button
      variant="contained"
      color="primary"
											  onClick={Gotopage2}
      style={{ borderRadius: '36px', backgroundColor: '#000000',textTransform: 'none',fontSize:'16px',fontFamily:'Outfit',fontWeight:600,width:'271px',height:'46px' }}
    >
      Sign up with Email
    </Button>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'12px'}}>
       
		<Typography onClick={Gotopage3 } sx={{fontSize: '12px',fontFamily:'Outfit', color: '#333333',fontWeight:'500',color:'#5B5A5A'}}> Already have an account? <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Login</span></Typography>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'26px'}}>
          <Typography style={{ fontSize: '12px',fontFamily:'Outfit', color: '#333333',fontWeight:'500' }}>By continuing, you agree to our <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Terms of <br/> Service</span>   and <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Outfit',fontSize:'12px' }}>Privacy Policy.</span></Typography>
        </Grid>
      </DialogContent>
          <DialogActions style={{ justifyContent: "space-between"}}>
										
          
			
						
          </DialogActions>
          </Dialog>
  </Grid>	
  <Grid item lg={3.8} xs={6} >
    <CustomButton onClick={() => setOpen(true)}variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Outfit' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height: mobile?'40px':"48px" ,  background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: '#111204',fontStyle:'normal' }}>
      Writer
							  </CustomButton>
						
  </Grid>
</Grid>  }			  
				
				  </Grid>
				  <Grid container lg={12} xs={12}  >
							<Grid item  lg={12}  xs={12} sx={{marginTop:'0px',marginBottom:mobile?"0px":"0px"}} >	
								
								
             
      {/* <Image
        duration={0}
        src="https://drive.google.com/uc?export=view&id=12Krz59BrmVX6APRgDE5YzM6vck2zHWwS"
        style={{ width: '456px', height: '456px', transitionDuration: '0', animation: '0', position: 'absolute', zIndex: 0 }}
      /> */}
                
                {/* <Image
  duration={0}
  src={Slush}
  style={{
    width:mobile?"290px":'426px',
    height:mobile?'290px': '426px',
    marginTop: mobile ? '156px' : '64px',
    transitionDuration: '0',
    animation: '0',
    zIndex: 1,
    borderRadius: '50%',
  // To maintain the aspect ratio of the image within the circle
  }}
/>
       */}
   
   <ImageSlider1 />			
									
</Grid>			
						
				</Grid>
				  </Grid>
				  <div
        className="pointer"
        style={{
          position: 'absolute',
		  width: `clamp(200px, 416px, 100%)`, // Set a width range from 200px to 416px, constrained by 100% width
		  height: `clamp(200px, 309px, 100%)`,
          backgroundColor: '#B9FD50',
			pointerEvents: 'none',
			opacity: '0.6',
			borderRadius:mobile?"200px": '416px',
			filter: 'blur(174px)',
			left: `calc(${position.x}px - ${mobile ? "100px" : "208px"})`,
			top: `calc(${position.y}px - ${mobile ? "100px" : "154.5px"})`,
          transition: 'transform 0.1s ease'
        }}
      ></div>
			  <Grid container lg={10} xs={12} sx={{margin:'auto',}}>

			
        <Grid container lg={12} xs={10}  sx={{margin:'auto', backgroundColor:'#111204' ,width:mobile?'272px':'1140px',height:mobile?'750px':'350px', borderRadius:'26px',marginTop:mobile?'67px':'147px'}}>
				  <Grid container lg={12} >
           <Grid item lg={12} sx={{margin:'auto',marginTop:mobile?'18px':""}} >
				   <Typography sx={{ textAlign: 'center',color:'#FFF',fontFamily:'Outfit' ,fontSize:mobile?'12px': '24px', fontWeight: "500", }}>Your manuscript can</Typography>
				  
						  </Grid>
				   
			<Grid container lg={10.5} xs={10} sx={{margin:'auto',marginTop:'25px',alignItems:'end'}}>   
				   
  <Grid container lg={4}  >
 
  <Grid item lg={12} xs={12} >
   <SvgIcon component={Icon4} style={{width:'56px',height:'56px'}} />
    </Grid>
    <Grid item lg={12} xs={12} sx={{marginTop:'12px'}}>
    <Typography  sx={{fontFamily:'Outfit' ,fontSize:mobile?'14px': '20px', fontWeight: "600",textAlign:'start',color:'#FFF' }}>
	1. Get 100% Publishing Assistance
    </Typography>
  
								  </Grid>
								  <Grid item lg={11} xs={12} sx={{marginTop:'12px'}}>
    <Typography  sx={{fontFamily:'Outfit' ,fontSize:mobile?'14px': '16px', fontWeight: "600",textAlign:'start',color:'#FFF' }}>
	Get comprehensive solutions and guidance each step of the way, from editing to distribution. Let us help you achieve your dreams of being an author with 100% publishing assistance. </Typography>

    </Grid>

     
  </Grid>
  
  <Grid container lg={4}  sx={{marginTop:mobile?'26px':'-19px'}}>
  <Grid item lg={12}  xs={12}>
  <SvgIcon component={Icon5} style={{width:'56px',height:'56px'}} />
  </Grid>
  <Grid item lg={12} xs={12} sx={{marginTop:mobile?'0px':'12px'}}>
 <Typography  sx={{fontFamily:'Outfit' ,fontSize:mobile?'14px': '20px', fontWeight: "600",textAlign:'start',color:'#FFF' }}>
 2. Get Published
    </Typography>
								  </Grid>
								  <Grid item lg={11} xs={12} sx={{marginTop:'12px'}}>
    <Typography  sx={{fontFamily:'Outfit' ,fontSize:mobile?'14px': '16px', fontWeight: "600",textAlign:'start',color:'#FFF' }}>
								  With our expertise we will pitch your books to our extended network of editors and get your manuscript published. Take your first step towards becoming a published author today.
	</Typography>
    </Grid>
</Grid>
<Grid container lg={4} sx={{marginTop:mobile?'26px':'0px'}} >
  <Grid item lg={12} xs={12}>
  <SvgIcon component={Icon6} style={{width:'56px',height:'56px'}} />
  </Grid>
  <Grid item lg={12} xs={12} sx={{marginTop:'12px'}}>
 <Typography  sx={{fontFamily:mobile?'Outfit':'Outfit' ,fontSize:mobile?'14px': '20px', fontWeight:mobile?'600': "600",textAlign:'start',color:'#fff' }}>
 3. Get Produced to movies
    </Typography>
								  </Grid>
								  <Grid item lg={10} xs={12} sx={{marginTop:'12px'}}>
    <Typography  sx={{fontFamily:'Outfit' ,fontSize:mobile?'14px': '16px', fontWeight: "600",textAlign:'start',color:'#FFF' }}>
	Breathe life into your words, and let us turn your book into a blockbuster. From securing film rights to collaborating with filmmakers, we will guide you throughout the journey.  </Typography>
  
    </Grid>
</Grid>
</Grid>
</Grid>
</Grid>	
</Grid>
{mobile?<Grid container lg={6} xs={9} sx={{margin:mobile?'auto':'',}}  >
  <Grid item lg={10} xs={12} sx={{marginTop:mobile?'35px':'0px'}}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'16px':'26px', fontWeight: mobile?'600': "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:mobile?'left':'left', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'26px': '48px', fontWeight:mobile?'700':"700", color: '#1E1E1E' }}>Shakuni</Typography>
  </Grid>
  <Grid item lg={11.5} xs={10.5} >
    <Typography sx={{textAlign:'left', fontFamily:mobile?'Outfit': 'Outfit', fontSize:mobile?'14px':'20px', fontWeight: mobile?'400': "400", color: '#1E1E1E',lineHeight:'146%' }}><span style={{fontWeight:'600'}}>A tale of revenge, love and rebirth</span> when an ancient enemy returns to a modern world.  <br/>
    Written by Harsha, published by Nu Voice Press, distributed by Simon and Schuster.<br/> </Typography>
  </Grid>
  <Grid item lg={12} xs={12} >
    <Typography sx={{textAlign:'left', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'14px': '16px', fontWeight: mobile?'400': "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={1.5} xs={10} sx={{ marginTop: '8px', display: mobile?'flex':'', justifyContent: mobile ? 'left' : '',  }}>
  <img
    duration={0}
    src={Slush4}
    style={{
      width: mobile ? '46px' : '63px',
      height: mobile ? '56px' : '77px',
      transitionDuration: '0',
      animation: '0',
      alignItems:'initial',
    }}
  />
</Grid>

</Grid>:''}





<Grid container lg={12} xs={12}>
  <Grid container lg={12} xs={12} sx={{marginTop:mobile?'20px':'166px',justifyContent:mobile?'center':''}}>
    <Grid container lg={6} xs={12}>
<Grid item lg={12} xs={12} sx={{position: 'relative', '&::after': { content: '""', position: 'absolute', bottom: 0, left: 0, width: '90%', height: mobile?"215px":'369px', background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)',zIndex:0,borderRadius:mobile?"0px 50px 50px 0px":'0px 100px 100px 0px',opacity:'0.5' }}}>

<Image
          duration={0}
          src={Slush5}
          style={{ width: mobile?'197px':'317px', height:mobile?'270px': '464px', transitionDuration: '0', animation: '0', zIndex: 1,marginBottom:'-20px'}}
									  />
									 
</Grid>    


    </Grid>
   {mobile?'': <Grid container lg={6} xs={9} >
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: 'Outfit', fontSize: '26px', fontWeight: "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: 'Outfit', fontSize: '48px', fontWeight: "700", color: '#1E1E1E' }}>Shakuni</Typography>
  </Grid>
  <Grid item lg={11} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: 'Outfit', fontSize: '20px', fontWeight: "400", color: '#1E1E1E',lineHeight:'146%' }}> <span style={{fontWeight:'600'}}>A tale of revenge, love and rebirth</span> when an ancient enemy returns to a modern world.<br/>
    Written by Harsha, published by Nu Voice Press, distributed by Simon and Schuster.<br/> </Typography>
  </Grid>
  <Grid item lg={12} xs={12} >
    <Typography sx={{textAlign:'left', fontFamily: 'Outfit', fontSize: '16px', fontWeight: "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={1.5} sx={{marginTop:'8px'}} >
  <Image
    duration={0}
    src={Slush6}
    style={{
   
      width: '63px',
      height: '77px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
</Grid>}

  </Grid>
</Grid>
<Grid container lg={12} xs={9} sx={{margin:mobile?'auto':'',}} >
				  <Grid container lg={12} xs={12} sx={{marginTop:'75px'}}>
  <Grid container lg={6} sx={{marginTop:mobile?'-60px':"0px",justifyContent:'center'}}>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'16px':'26px', fontWeight: mobile?'600': "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:mobile?'left':'left', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'26px': '48px', fontWeight:mobile?'700':"700", color: '#1E1E1E' }}>Kaliyuga</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily:mobile?'Outfit': 'Outfit', fontSize:mobile?'14px':'20px', fontWeight: mobile?'400': "400", color: '#1E1E1E',lineHeight:'146%' }}><span style={{fontWeight:'600'}}>Mythology, Science and Fantasy</span> come together in a story of the battle between good and evil.<br/>
Written by Harsheath Chauhan, Kaliyuga is published by Nu Voice Press, distributed by Simon and Schuster.</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: mobile?'Outfit': 'Outfit', fontSize: mobile?'14px': '16px', fontWeight: mobile?'400': "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={10} xs={12} sx={{ marginTop: '8px', display: mobile?'flex':'', justifyContent: mobile ? 'left' : '',  }} >
  <img
    duration={0}
    src={Slush7}
    style={{
   
      width: '63px',
      height: '77px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
</Grid>
					  {mobile?'':<Grid container lg={6} xs={12} sx={{marginTop:mobile?'31px':'0px'}}>
<Grid item lg={12}  xs={12} sx={{position: 'relative', '&::after': { content: '""', position: 'absolute', bottom: 0, left: 0, width: '100%', height: mobile?"215px":'369px', background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)',zIndex:0,borderRadius:mobile?"50px 0px 0px 50px":'100px 0px 0px 100px',opacity:'0.5' }}}>

								  <Image
									  duration={0}
									  src={Slush8}
									  style={{ width:mobile?'197px': '317px', height:mobile?'270px': '439px', transitionDuration: '0', animation: '0', zIndex: 1,marginBottom:'-20px' }}
        />

</Grid>    
 

    </Grid>}
  </Grid>
</Grid>
{mobile?<Grid container lg={6} xs={12} sx={{marginTop:mobile?'31px':'0px',justifyContent:'end'}}>
<Grid item lg={12}  xs={10} sx={{position: 'relative', '&::after': { content: '""', position: 'absolute', bottom: 0, left: 0, width: '100%', height: mobile?"215px":'369px', background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)',zIndex:0,borderRadius:mobile?"50px 0px 0px 50px":'100px 0px 0px 100px',opacity:'0.5' }}}>

								  <Image
									  duration={0}
									  src={Slush8}
									  style={{ width:mobile?'197px': '317px', height:mobile?'270px': '439px', transitionDuration: '0', animation: '0', zIndex: 1,marginBottom:'-20px' }}
        />

</Grid>    
 

    </Grid>:''}
		
{/* <Grid container lg={12} xs={10} sx={{ margin: 'auto', backgroundColor: '#DAFDF5', borderRadius: '26px', marginTop:mobile?'45px': '147px' }}>
  <Grid container lg={10} xs={10} sx={{margin:'auto',marginBottom:'40px'}}>
						  <Grid container lg={12} xs={12}  >
							  <Typography sx={{textAlign: 'initial', marginTop:mobile?'25px': '40px', fontSize:mobile?'14px': '24px', fontWeight:mobile?'500': "500", color: '#1E1E1E', fontFamily:mobile?'Outfit': 'Outfit' }}>Build a community of readers and get published by the best in the industry</Typography>
    
  </Grid>
 
					 
						  
  <Grid container lg={7}  >
    <Grid item lg={12} sx={{marginTop:mobile?'19px':'56px'}}>
      <img
        duration={0}
        src={Slush9}
        style={{
          width:mobile?'36px': '60px',
          height:mobile?'31px': '60px',
          transitionDuration: '0',
          animation: '0',justifyContent:'start'
        }}
      />
      </Grid>
      <Grid item lg={9.5} xs={12} sx={{marginTop:mobile?'15px':'26px'}}>
      <Typography sx={{ fontFamily:mobile?'Outfit': 'Outfit', fontSize:mobile?'14px': '20px',  textAlign: 'initial',lineHeight:'139%' }}>
  <span style={{ color: '#1e1e1e',fontWeight:mobile?'600': "600", }}>Readers:</span> Discover free and unlimited fascinating stories across genres. Support your favourite stories and help them get published.
</Typography>

</Grid>
  </Grid>

  <Grid container lg={5}  >
    <Grid item lg={12} sx={{marginTop:mobile?'19px':'56px'}} >
      <img
        duration={0}
        src={Slush10}
        style={{
          width:mobile?'40px': '64px',
          height:mobile?'40px': '64px',
          transitionDuration: '0',
          animation: '0',justifyContent:'start'
        }}
      />
      </Grid>

      <Grid item lg={11.5}  xs={12} sx={{marginTop:mobile?'15px':'26px'}} >
      <Typography sx={{ fontFamily: mobile?'Outfit':'Outfit', fontSize: mobile?'14px':'20px',  textAlign: 'initial',lineHeight:'139%' }}>
  <span style={{ color: '#1e1e1e',fontWeight:mobile?'600': "600", }}>Writers:</span> Take your manuscript out of the slush pile and to your readers.Build a community of readers and get published.
</Typography>

</Grid>

							  </Grid>
							  
               
</Grid>

</Grid> */}



<Grid container lg={12} xs={12} sx={{ backgroundColor: '#111204', marginTop:mobile?'56px': '140px',  }}>
  <Grid container lg={12} xs={12} sx={{ margin:mobile?'0px': 'auto 64px', marginBottom: '64px' }}>
    <Grid item lg={12} xs={12}>
      <Typography sx={{ textAlign: 'center', marginTop: '64px', color: '#fff', fontFamily:mobile?'Outfit': 'Outfit', fontSize:mobile?'26px': '48px', fontWeight:mobile?'700': "700" }}>
        How <span style={{ backgroundImage: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: 'transparent', WebkitBackgroundClip: 'text', backgroundClip: 'text' }}>Slushie</span> Works
      </Typography>
    </Grid>
    <Grid container lg={11}  xs={10}sx={{ margin: 'auto', marginTop:mobile?'25px': '80px',justifyContent:mobile?'center':'' }}>
      <Grid container lg={4} xs={10} style={{alignItems:'center'}}>
							  <Grid item lg={2.3} xs={3} sx={{ textAlign: mobile ? 'center' : 'center', background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', borderRadius: '50%', width: mobile?'56px':"100px", height:mobile?'56px': '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								  <IcSharpRocketLaunch style={{ width: mobile?"26px":"56px", height: mobile?"26px":"56px" }} />
							  </Grid>
							  {mobile ? '' : <Grid item lg={9.5} xs={12} sx={{textAlign:mobile?'initial':'center',marginLeft:'0px'}}>
								  <SvgIcon component={Arrowficon} style={{width:'90%',marginRight:'50px'}} />
							  </Grid>}
        <Grid item lg={12} xs={12}>
          <Typography sx={{ fontFamily: mobile?'Outfit':'Outfit', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign: mobile?'initial':'initial',color:'#FFF' }}>
            Sign Up
          </Typography>
        </Grid>
        <Grid item lg={10.7} xs={12} sx={{ textAlign: 'left', width: '369px',}}>
          <Typography sx={{ fontFamily:mobile?'Outfit': 'Outfit', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign:mobile?'initial': 'initial',color:'#FFF' }}>
            Sign up and upload your finished manuscript. After verification, choose 3 chapters that will go live for readers.
            Don’t worry your manuscript is 100% safe with us!
          </Typography>
        </Grid>
      </Grid>

      <Grid container lg={4} xs={10} sx={{marginTop:mobile?'25px':'',alignItems:'center'}} >
	  <Grid  item lg={2.3}  xs={3} sx={{textAlign:mobile?'center':'center',background:'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)',borderRadius:'50%',width: mobile?'56px':"100px", height:mobile?'56px': '80px',display:'flex',alignItems:'center',justifyContent:'center'}}>
								  <MaterialSymbolsHowToVoteRounded style={{ width: mobile ? "26px" : "56px", height: mobile ? "26px" : "56px" }} />
							  </Grid>
							  {mobile ? '' : <Grid item lg={9.5} xs={12} sx={{textAlign:mobile?'initial':'center',marginLeft:'0px'}}>
								  <SvgIcon component={Arrowficon} style={{width:'90%',marginRight:'50px'}} />
							  </Grid>}
        <Grid item lg={12} xs={12}>
          <Typography  sx={{ fontFamily: mobile?'Outfit':'Outfit', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign:mobile?'initial': 'initial',color:'#FFF' }}>
            Get Votes
          </Typography>
        </Grid>
        <Grid item lg={10.7} xs={12} sx={{ textAlign: 'left' ,marginBottom:'32px'}}>
          <Typography sx={{ fontFamily:mobile?'Outfit': 'Outfit', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign:mobile?'initial': 'initial',color:'#FFF' }}>
            Get 500 votes on your manuscript for guaranteed publishing assistance.
            Connect with your audience and leave the rest to us!
          </Typography>
        </Grid>
      </Grid>

      <Grid container lg={4} xs={10} style={{alignItems:'center'}}>
	  <Grid  item lg={2.3}  xs={3} sx={{textAlign:mobile?'center':'center',background:'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)',borderRadius:'50%',width: mobile?'56px':"100px", height:mobile?'56px': '80px',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <MaterialSymbolsBook2Rounded style={{ width: mobile ? "26px" : "56px", height: mobile ? "26px" : "56px" }}/>
							  </Grid>
							 
        <Grid item lg={12} xs={12}>
          <Typography sx={{ fontFamily: mobile?'Outfit':'Outfit', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign:mobile?'initial': 'initial',color:'#FFF' }}>
            Be Published
          </Typography>
        </Grid>
        <Grid item lg={10} xs={12} sx={{ textAlign: 'left' ,marginBottom:'32px'}}>
          <Typography sx={{ fontFamily:mobile?'Outfit': 'Outfit', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign: mobile?'initial':'initial',color:'#FFF' }}>
            Bridge the gap between your manuscript and your readers’ shelves.
            Let’s give your readers their next favorite read!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</Grid>



<Grid container lg={12} xs={12} sx={{marginTop:mobile?'27px':'116px',marginBottom:'116px'}}>
  
  <Grid item lg={12} xs={12}>
  <Typography sx={{ textAlign: 'center',marginTop:'48px',color:'#1E1E1E',fontFamily:mobile?'Outfit':'Outfit' ,fontSize: mobile?'26px':'48px', fontWeight:mobile?'700': "700", }}>Brand And Industry</Typography>
  </Grid>
  {/* <Grid item lg={12} xs={12}>
  <Typography sx={{ textAlign: 'center',marginTop:'0px',color:'#1E1E1E',fontFamily:'Outfit' ,fontSize:mobile?'14px': '26px', fontWeight: "400", }}>Authors We Have Worked With</Typography>
				  </Grid> */}
  <Grid container lg={10} xs={10} sx={{margin:'auto',}} >
				  <ImageSlider />
		
      
				  {/* <Grid item lg={1.7} sx={{marginTop:'63px'}} >
          <Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1ALo4GanQJOsZM9XkaXMIQM0Yv5sjo_Tt"
          style={{ width: '72px', height: '90px', transitionDuration: '0', animation: '0', }}
        />
          </Grid> */}
          {/* <Grid item lg={1.7}  sx={{marginTop:'63px'}}>
          <Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1rQQSZVoW6QRlotSY5CQgHt-r-GNEkKEX"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}} >
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1CTsjPJg3DnjfU8RaTxlmkM1aA_ZSA0vL"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7}  sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1sdd7TzUHvpQA_vWdHk16DdSOYlyHnmzU"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}} >
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1vxG9uPSOxvOLuOsSfu2TuCRlTGkDszeR"
          style={{ width: '79px', height: '77px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1baDiiZu06dZ5JgiH5vcduuA1WQDOLuZ2"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1baDiiZu06dZ5JgiH5vcduuA1WQDOLuZ2"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid> */}

</Grid>

			  </Grid>
			  <Grid container lg={12} xs={12} sx={{ position: 'relative',margin:mobile?'auto':"", '&::after': { content: '""', position: 'absolute', bottom: 0, left: 0, width: '100%', height: mobile?"400px":'200px', background: '#111204',zIndex:0 } }}>
<Grid container lg={10.3} xs={10} sx={{borderRadius: '35px',background: '#1E2008',margin:'auto',justifyContent:mobile?'center':'',marginTop:mobile?'-147px':'',zIndex:1,marginBottom:mobile?"50px":""}}>
					  {mobile ? '' : <Grid container lg={4}>
						  <Image src={Brew} style={{ width: '278px', height: "296px" }} />
					  </Grid>}
				  <Grid container lg={4} xs={10} sx={{ margin: 'auto', marginTop: '86px', marginBottom: '86px', textAlign: 'center' }}>
<Grid item lg={12} xs={12}>
  <Typography sx={{fontSize:mobile?'26px':'48px',fontWeight:mobile?'700':'700',fontFamily:mobile?'Outfit':'Outfit',color:'#ffffff',}}>What’s Brewing?</Typography>
</Grid>

<Grid item lg={12} xs={12} >
  {mobile? <Typography sx={{fontSize:mobile?'14px':'20px',fontWeight:mobile?'400':'400',fontFamily:mobile?'Outfit':'Outfit',color:'#ffffff'}}>Welcome to the hotbed of contemporary literature straight from the writer’s desk to the reader’s screens.</Typography>:
  <Typography sx={{fontSize:mobile?'14px':'20px',fontWeight:mobile?'400':'400',fontFamily:mobile?'Outfit':'Outfit',color:'#ffffff'}}>Welcome to the hotbed of contemporary literature straight from the writer’s desk to the reader’s screens.</Typography>
}</Grid>
{token?"":<Grid container lg={12}  xs={10} sx={{margin:'auto',marginTop:'30px',justifyContent:'space-between'}}>
<Grid item lg={6} xs={6}>
    <CustomButton1 onClick={()=>setOpen1(true)}  variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Outfit' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height: mobile?'36px':"48px" ,  background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: '#111204',fontStyle:'normal' }}>
      Reader
    </CustomButton1>
	
  </Grid>	
  <Grid item lg={6} xs={6}>
    <CustomButton1  onClick={()=>setOpen(true)} variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Outfit' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height:mobile?'36px': "48px" , background: 'linear-gradient(180deg, #007F85 0%, #FEDF62 100%)', color: '#111204',fontStyle:'normal' }}>
   Writer
    </CustomButton1>
    
  </Grid>
  </Grid>}	
				  </Grid>
				  <Grid container lg={4}>
				  <Image src={Brew}  style={{width:'211px',height:"225px"}}  />	  
					  </Grid>
</Grid>
			
</Grid>	
      </Grid>
      <Footer1/>
      </>
  )
}

export default Landingpage2